<style>
  .settings-collapse {
    margin-bottom: 1rem;
  }
  .hard-border {
    border-radius: 0 !important;
  }
  .overlay {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 2;
  }
  .overlay-text{
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
  }
</style>
<script>
import {  VclCode } from 'vue-content-loading';
import { HalfCircleSpinner } from 'epic-spinners';
import Multiselect from "vue-multiselect";

import CopyableText from "@/components/CopyableText";
import {get_acsrf_token} from "@/methods";
import {mapActions, mapGetters} from "vuex";
import CopyableInput from "@/components/CopyableInput";
import {BucketType, Game} from "@/enums";
import store from "@/state/store";

export default {
  props: [],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    VclCode,
    // eslint-disable-next-line vue/no-unused-components
    HalfCircleSpinner,
    // eslint-disable-next-line vue/no-unused-components
    Multiselect
  },
  computed: {
    ...mapGetters(['getUILanguage']),
    ...mapActions({
      setPreferences: 'setPreferences',
      setDTLocale: 'setDTLocale',
    })
  },
  methods: {
    async onToggleEntitlement(key, event) {
      this.transmission[key] = true;
      setTimeout(() => {
        this.updateEntitlement(key, event.value);
      }, 750);
    },
    async updateEntitlement(key, value) {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          key: key,
          value: value
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/account/entitlement`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          this.entitlements = data.entitlements;
        } else {
          if(response.status === 429) {
            this.entitlements[key] = !value;
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        this.entitlements[key] = !value;
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.transmission[key] = false;
    },
    syncDWP() {
      localStorage.displayWatcherPopup = `${this.ls_linked.displayWatcherPopup}`;
    },
    async onTogglePreference(key, event) {
      this.transmission[key] = true;
      setTimeout(() => {
        this.updatePreference(key, event.value);
      }, 750);
    },
    async onChangePreference(key, ref, value) {
      this.transmission[key] = true;
      this.$refs[ref].$el.disabled = true;
      await this.updatePreference(key, value || this.$refs[ref].$el.value, this.$refs[ref].$el);
    },
    async updatePreference(key, value, input) {
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          key: key,
          value: value
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/account/preference`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          if(input) {
            input.classList.add('is-valid');
            setTimeout(() => {
              input.classList.remove('is-valid');
            }, 750);
          }
          let data = await response.json();
          this.$store.commit('setPreferences', data.preferences);
          this.$store.commit('setDTLocale', data.preferences.datetime_locale || this.getUILanguage());
        } else {
          if(response.status === 429) {
            this.entitlements[key] = !value;
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        this.entitlements[key] = !value;
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.transmission[key] = false;
    },
    getPreferences: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/account/preferences`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.preferences = data.preferences;
          this.entitlements = data.entitlements;
          this.availability = data.availability;
          this.notification = data.notification;
          this.notifications = data.notifications;
          this.$nextTick(() => {
              this.ready = true;
          });
        })
        .catch(error => {
          this.handleError(error);
        });
    },
  },
  mounted() {
    this.getPreferences();
    this.ls_linked.displayWatcherPopup = (!localStorage.displayWatcherPopup || localStorage.displayWatcherPopup === null || localStorage.displayWatcherPopup === 'true');
    this.audio.audio_alert_admin.volume = 0.05;
    this.audio.audio_alert_watcher.volume = 0.05;
  },
  data() {
    return {
      ready: false,
      error: false,
      inProgress: false,
      date: new Date(),
      audio: {
        audio_alert_admin: new Audio(require('@/assets/audio/error.wav')),
        audio_alert_watcher: new Audio(require('@/assets/audio/warning.wav')),
      },
      preferences: {},
      entitlements: {},
      availability: {},
      notification: {},
      notifications: {},
      ls_linked: {
        displayWatcherPopup: null
      },
      transmission: {
        // entitlements
        foundersBadge: false,
        foundersBorder: false,
        extendedPrivacy: false,
        // preferences
        datetime_locale: false,
        dashboard_kdratio: false,
        interaction_name: false,
        audio_alert_admin: false,
        audio_alert_watcher: false,
        gameintegration_teleport: false,
        gameintegration_vehicles: false,
        gameintegration_events: false,
        gameintegration_players: false,
        gameintegration_custom: false,
        'notification.email': false,
        'notifications.general': false,
        'notifications.advertisement': false,
        'notifications.alert': false
      }
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div>
            <div role="tablist">
              <!-- UI -->
              <b-card no-body class="settings-collapse hard-border">
                <a v-b-toggle.ui class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('preferences.ui.title') }}
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('preferences.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="ui" accordion="preferences" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <!-- Datetime localization -->
                      <div class="row">
                        <div class="w-50">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('preferences.ui.datetime_locale.title') }}
                            </h4>
                            <span>
                              {{ $t('preferences.ui.datetime_locale.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-50">
                          <b-form-group label=" ">
                            <b-form-radio-group
                                ref="uiDatetimeLocale"
                                v-model="preferences.datetime_locale"
                                @change="onChangePreference('datetime_locale', 'uiDatetimeLocale', preferences.datetime_locale)"
                            >
                              <b-form-radio value="en">
                                {{ $d(date, 'datetime', 'en')}}
                              </b-form-radio>
                              <b-form-radio value="de">
                                {{ $d(date, 'datetime', 'de')}}
                              </b-form-radio>
                            </b-form-radio-group>
                          </b-form-group>
                        </div>
                      </div>

                      <!-- Dashboard: Show KD -->
                      <div class="row mt-2">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('preferences.ui.dashboard_kdratio.title') }}
                            </h4>
                            <span>
                              {{ $t('preferences.ui.dashboard_kdratio.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <toggle-button ref="dashboardKdratio" v-model="preferences.dashboard_kdratio" @change="onTogglePreference('dashboard_kdratio', $event)"
                                           :disabled="transmission.dashboard_kdratio"
                                           :sync="true"
                                           :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                           :height="40"
                                           :width="80"
                                           :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                           style="font-size: 13px !important;"
                                           class="m-0 mt-1 mb-1"
                            />
                            <div class="d-inline ml-3" v-if="$refs.dashboardKdratio && $refs.dashboardKdratio.disabled">
                              <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Profile -->
              <b-card no-body class="settings-collapse hard-border">
                <a v-b-toggle.profile class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('preferences.profile.title') }}
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('preferences.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="profile" accordion="preferences" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
	                    <template v-if="availability.foundersEdition">
	                      <!-- Founders Badge -->
	                      <div class="row">
	                        <div class="w-75">
	                          <div class="ml-4 mr-4">
	                            <h4>
	                              {{ $t('preferences.profile.founders_badge.title') }}
		                            <button class="btn btn-light btn-sm" id="foundersBadge" style="background: #e2b007;">
			                            <i class="fad fa-stars font-size-16"></i>
		                            </button>
	                            </h4>
	                            <span>
	                              {{ $t('preferences.profile.founders_badge.description') }}
	                            </span>
	                          </div>
	                        </div>
	                        <div class="w-25">
	                          <template v-if="availability.foundersEdition">
	                            <div style="text-align: center;">
	                              <toggle-button ref="profileFoundersBadge" v-model="entitlements.foundersBadge" @change="onToggleEntitlement('foundersBadge', $event)"
	                                             :disabled="transmission.foundersBadge"
	                                             :sync="true"
	                                             :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
	                                             :height="40"
	                                             :width="80"
	                                             :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
	                                             style="font-size: 13px !important;"
	                                             class="m-0 mt-1 mb-1"
	                              />
	                              <div class="d-inline ml-3" v-if="$refs.profileFoundersBadge && $refs.profileFoundersBadge.disabled">
	                                <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
	                              </div>
	                            </div>
	                          </template>
	                          <template v-else>
	                            <h3 class="text-danger text-center">
	                              <i class="fad fa-times-circle" />
	                            </h3>
	                            <h6 class="p-0 text-danger text-center">{{ $t('preferences.profile.founders_badge.unavailable') }}</h6>
	                          </template>
	                        </div>
	                      </div>

	                      <!-- Founders Border -->
	                      <div class="row mt-2" >
	                        <div class="w-75">
	                          <div class="ml-4 mr-4">
	                            <h4>
	                              {{ $t('preferences.profile.founders_border.title') }}
	                            </h4>
	                            <span>
	                              {{ $t('preferences.profile.founders_border.description') }}
	                            </span>
	                          </div>
	                        </div>
	                        <div class="w-25">
	                          <template v-if="availability.foundersEdition">
	                            <div style="text-align: center;">
	                              <toggle-button ref="profileFoundersBorder" v-model="entitlements.foundersBorder" @change="onToggleEntitlement('foundersBorder', $event)"
	                                             :disabled="transmission.foundersBorder"
	                                             :sync="true"
	                                             :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
	                                             :height="40"
	                                             :width="80"
	                                             :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
	                                             style="font-size: 13px !important;"
	                                             class="m-0 mt-1 mb-1"
	                              />
	                              <div class="d-inline ml-3" v-if="$refs.profileFoundersBorder && $refs.profileFoundersBorder.disabled">
	                                <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
	                              </div>
	                            </div>
	                          </template>
	                          <template v-else>
	                            <h3 class="text-danger text-center">
	                              <i class="fad fa-times-circle" />
	                            </h3>
	                            <h6 class="p-0 text-danger text-center">{{ $t('preferences.profile.founders_border.unavailable') }}</h6>
	                          </template>
	                        </div>
	                      </div>
	                    </template>

                      <!-- P2022 -->
                      <template v-if="availability.p2022">
                        <!-- Badge -->
                        <div class="row mt-2">
                          <div class="w-75">
                            <div class="ml-4 mr-4">
                              <h4>
                                CFTools Partner Badge (2022 Edition)
                                <button class="btn btn-light btn-sm" style="background: #6869AC;">
                                  <i class="fad fa-globe-stand font-size-16"></i>
                                </button>
                              </h4>
                              <span>
                                Exclusive profile badge for CFTools Partners, 2022 Edition
                              </span>
                            </div>
                          </div>
                          <div class="w-25">
                            <div style="text-align: center;">
                              <toggle-button ref="profilep2022Badge" v-model="entitlements.p2022Badge" @change="onToggleEntitlement('p2022Badge', $event)"
                                             :disabled="transmission.p2022Badge"
                                             :sync="true"
                                             :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                             :height="40"
                                             :width="80"
                                             :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                             style="font-size: 13px !important;"
                                             class="m-0 mt-1 mb-1"
                              />
                              <div class="d-inline ml-3" v-if="$refs.profilep2022Badge && $refs.profilep2022Badge.disabled">
                                <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Border -->
                        <div class="row mt-2">
                          <div class="w-75">
                            <div class="ml-4 mr-4">
                              <h4>
                                CFTools Partner Border (2022 Edition)
                              </h4>
                              <span>
                                Exclusive animated profile border for CFTools Partners, 2022 Edition
                              </span>
                            </div>
                          </div>
                          <div class="w-25">
                            <div style="text-align: center;">
                              <toggle-button ref="profilep2022Border" v-model="entitlements.p2022Border" @change="onToggleEntitlement('p2022Border', $event)"
                                             :disabled="transmission.p2022Border"
                                             :sync="true"
                                             :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                             :height="40"
                                             :width="80"
                                             :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                             style="font-size: 13px !important;"
                                             class="m-0 mt-1 mb-1"
                              />
                              <div class="d-inline ml-3" v-if="$refs.profilep2022Border && $refs.profilep2022Border.disabled">
                                <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

	                    <!-- P2023 -->
	                    <template v-if="availability.p2023">
		                    <!-- Badge -->
		                    <div class="row mt-2">
			                    <div class="w-75">
				                    <div class="ml-4 mr-4">
					                    <h4>
						                    CFTools Partner Badge (2023 Edition)
						                    <button class="btn btn-light btn-sm" id="p2023Badge" style="background: #b44659;">
							                    <i class="fad fa-globe-stand font-size-16"></i>
						                    </button>
					                    </h4>
					                    <span>
                                Exclusive profile badge for CFTools Partners, 2023 Edition
                              </span>
				                    </div>
			                    </div>
			                    <div class="w-25">
				                    <div style="text-align: center;">
					                    <toggle-button ref="profilep2023Badge" v-model="entitlements.p2023Badge" @change="onToggleEntitlement('p2023Badge', $event)"
					                                   :disabled="transmission.p2023Badge"
					                                   :sync="true"
					                                   :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
					                                   :height="40"
					                                   :width="80"
					                                   :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
					                                   style="font-size: 13px !important;"
					                                   class="m-0 mt-1 mb-1"
					                    />
					                    <div class="d-inline ml-3" v-if="$refs.profilep2023Badge && $refs.profilep2023Badge.disabled">
						                    <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
					                    </div>
				                    </div>
			                    </div>
		                    </div>

		                    <!-- Border -->
		                    <div class="row mt-2">
			                    <div class="w-75">
				                    <div class="ml-4 mr-4">
					                    <h4>
						                    CFTools Partner Border (2023 Edition)
					                    </h4>
					                    <span>
                                Exclusive animated profile border for CFTools Partners, 2023 Edition
                              </span>
				                    </div>
			                    </div>
			                    <div class="w-25">
				                    <div style="text-align: center;">
					                    <toggle-button ref="profilep2023Border" v-model="entitlements.p2023Border" @change="onToggleEntitlement('p2023Border', $event)"
					                                   :disabled="transmission.p2023Border"
					                                   :sync="true"
					                                   :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
					                                   :height="40"
					                                   :width="80"
					                                   :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
					                                   style="font-size: 13px !important;"
					                                   class="m-0 mt-1 mb-1"
					                    />
					                    <div class="d-inline ml-3" v-if="$refs.profilep2023Border && $refs.profilep2023Border.disabled">
						                    <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
					                    </div>
				                    </div>
			                    </div>
		                    </div>
	                    </template>

	                    <!-- AdvancedSub -->
	                    <template v-if="availability.advanced">
		                    <!-- Badge -->
		                    <div class="row mt-2">
			                    <div class="w-75">
				                    <div class="ml-4 mr-4">
					                    <h4>
						                    Advanced Subscriber Badge
						                    <button class="btn btn-light btn-sm" style="background: #E5E4E2;">
							                    <i class="fad fa-hand-receiving font-size-14" style="--fa-primary-color: #1a1b1d; --fa-secondary-color: #1a1b1d;"></i>
						                    </button>
					                    </h4>
					                    <span>
                                Exclusive profile badge for Advanced Subscribers
                              </span>
				                    </div>
			                    </div>
			                    <div class="w-25">
				                    <div style="text-align: center;">
					                    <toggle-button ref="profileadvancedBadge" v-model="entitlements.advancedBadge" @change="onToggleEntitlement('advancedBadge', $event)"
					                                   :disabled="transmission.advancedBadge"
					                                   :sync="true"
					                                   :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
					                                   :height="40"
					                                   :width="80"
					                                   :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
					                                   style="font-size: 13px !important;"
					                                   class="m-0 mt-1 mb-1"
					                    />
					                    <div class="d-inline ml-3" v-if="$refs.profileadvancedBadge && $refs.profileadvancedBadge.disabled">
						                    <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
					                    </div>
				                    </div>
			                    </div>
		                    </div>

		                    <!-- Border -->
		                    <div class="row mt-2">
			                    <div class="w-75">
				                    <div class="ml-4 mr-4">
					                    <h4>
						                    Advanced Subscriber Border
					                    </h4>
					                    <span>
                                Exclusive animated profile border for Advanced Subscribers
                              </span>
				                    </div>
			                    </div>
			                    <div class="w-25">
				                    <div style="text-align: center;">
					                    <toggle-button ref="profileadvancedBorder" v-model="entitlements.advancedBorder" @change="onToggleEntitlement('advancedBorder', $event)"
					                                   :disabled="transmission.advancedBorder"
					                                   :sync="true"
					                                   :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
					                                   :height="40"
					                                   :width="80"
					                                   :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
					                                   style="font-size: 13px !important;"
					                                   class="m-0 mt-1 mb-1"
					                    />
					                    <div class="d-inline ml-3" v-if="$refs.profileadvancedBorder && $refs.profileadvancedBorder.disabled">
						                    <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
					                    </div>
				                    </div>
			                    </div>
		                    </div>
	                    </template>

                      <!-- Extended privacy -->
                      <div class="row mt-2">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('preferences.profile.extended_privacy.title') }}
                            </h4>
                            <span>
                              {{ $t('preferences.profile.extended_privacy.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <template v-if="availability.extendedPrivacy">
                            <div style="text-align: center;">
                              <toggle-button ref="profileExtendedPrivacy" v-model="entitlements.extendedPrivacy" @change="onToggleEntitlement('extendedPrivacy', $event)"
                                             :disabled="transmission.extendedPrivacy"
                                             :sync="true"
                                             :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                             :height="40"
                                             :width="80"
                                             :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                             style="font-size: 13px !important;"
                                             class="m-0 mt-1 mb-1"
                              />
                              <div class="d-inline ml-3" v-if="$refs.profileExtendedPrivacy && $refs.profileExtendedPrivacy.disabled">
                                <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <h3 class="text-danger text-center">
                              <i class="fad fa-times-circle" />
                            </h3>
                            <h6 class="p-0 text-danger text-center">{{ $t('preferences.profile.extended_privacy.unavailable') }}</h6>
                          </template>
                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Interaction -->
              <b-card no-body class="settings-collapse hard-border">
                <a v-b-toggle.interaction class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('preferences.interaction.title') }}
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('preferences.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="interaction" accordion="preferences" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <!-- Founders Badge -->
                      <div class="row">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('preferences.interaction.name.title') }}
                            </h4>
                            <span>
                              {{ $t('preferences.interaction.name.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <b-input ref="interactionInteractionName" v-model="preferences.interaction_name"  @change="onChangePreference('interaction_name', 'interactionInteractionName')" />
                        </div>
                      </div>

                      <!-- Audio: Admin -->
                      <div class="row mt-2">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('preferences.interaction.audio_admin.title') }}
                              <button class="btn btn-outline-info p-1 btn-sm btn-xs" v-on:click="audio.audio_alert_admin.play();">
                                <i class="fad fa-music-alt" /> Preview
                              </button>
                            </h4>
                            <span>
                              {{ $t('preferences.interaction.audio_admin.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <toggle-button ref="interactionAudioAdmin" v-model="preferences.audio_alert_admin" @change="onTogglePreference('audio_alert_admin', $event)"
                                           :disabled="transmission.audio_alert_admin"
                                           :sync="true"
                                           :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                           :height="40"
                                           :width="80"
                                           :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                           style="font-size: 13px !important;"
                                           class="m-0 mt-1 mb-1"
                            />
                            <div class="d-inline ml-3" v-if="$refs.interactionAudioAdmin && $refs.interactionAudioAdmin.disabled">
                              <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Watcher: PopUp -->
                      <div class="row mt-2">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              Watch Profile Event Pop-Up
                              <span class="badge badge-info text-black">
                                Device Setting
                              </span>
                            </h4>
                            <span>
                              Do not show pop-up when watcher event happens
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <toggle-button ref="interactionAudioWatcher" v-model="ls_linked.displayWatcherPopup" @change="syncDWP()"
                                           :sync="true"
                                           :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                           :height="40"
                                           :width="80"
                                           :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                           style="font-size: 13px !important;"
                                           class="m-0 mt-1 mb-1"
                            />
                          </div>
                        </div>
                      </div>

                      <!-- Audio: Watcher -->
                      <div class="row mt-2">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('preferences.interaction.audio_watcher.title') }}
                              <button class="btn btn-outline-info p-1 btn-sm btn-xs" v-on:click="audio.audio_alert_watcher.play();">
                                <i class="fad fa-music-alt" /> Preview
                              </button>
                            </h4>
                            <span>
                              {{ $t('preferences.interaction.audio_watcher.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <toggle-button ref="interactionAudioWatcher" v-model="preferences.audio_alert_watcher" @change="onTogglePreference('audio_alert_watcher', $event)"
                                           :disabled="transmission.audio_alert_watcher"
                                           :sync="true"
                                           :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                           :height="40"
                                           :width="80"
                                           :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                           style="font-size: 13px !important;"
                                           class="m-0 mt-1 mb-1"
                            />
                            <div class="d-inline ml-3" v-if="$refs.interactionAudioWatcher && $refs.interactionAudioWatcher.disabled">
                              <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- GameIntegration: Teleport -->
                      <div class="row mt-2">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('preferences.interaction.gameintegration_teleport.title') }}
                            </h4>
                            <span>
                              {{ $t('preferences.interaction.gameintegration_teleport.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <toggle-button ref="interactionGITeleport" v-model="preferences.gameintegration_teleport" @change="onTogglePreference('gameintegration_teleport', $event)"
                                           :disabled="transmission.gameintegration_teleport"
                                           :sync="true"
                                           :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                           :height="40"
                                           :width="80"
                                           :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                           style="font-size: 13px !important;"
                                           class="m-0 mt-1 mb-1"
                            />
                            <div class="d-inline ml-3" v-if="$refs.interactionGITeleport && $refs.interactionGITeleport.disabled">
                              <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- GameIntegration: Vehicles -->
                      <div class="row mt-2">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('preferences.interaction.gameintegration_vehicles.title') }}
                            </h4>
                            <span>
                              {{ $t('preferences.interaction.gameintegration_vehicles.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <toggle-button ref="interactionGIVehicles" v-model="preferences.gameintegration_vehicles" @change="onTogglePreference('gameintegration_vehicles', $event)"
                                           :disabled="transmission.gameintegration_vehicles"
                                           :sync="true"
                                           :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                           :height="40"
                                           :width="80"
                                           :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                           style="font-size: 13px !important;"
                                           class="m-0 mt-1 mb-1"
                            />
                            <div class="d-inline ml-3" v-if="$refs.interactionGIVehicles && $refs.interactionGIVehicles.disabled">
                              <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- GameIntegration: Vehicles -->
                      <div class="row mt-2">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('preferences.interaction.gameintegration_events.title') }}
                            </h4>
                            <span>
                              {{ $t('preferences.interaction.gameintegration_events.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <toggle-button ref="interactionGIEvents" v-model="preferences.gameintegration_events" @change="onTogglePreference('gameintegration_events', $event)"
                                           :disabled="transmission.gameintegration_events"
                                           :sync="true"
                                           :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                           :height="40"
                                           :width="80"
                                           :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                           style="font-size: 13px !important;"
                                           class="m-0 mt-1 mb-1"
                            />
                            <div class="d-inline ml-3" v-if="$refs.interactionGIEvents && $refs.interactionGIEvents.disabled">
                              <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                            </div>
                          </div>
                        </div>
                      </div>


                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Notification -->
              <b-card no-body class="settings-collapse hard-border">
                <a v-b-toggle.notification class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      {{ $t('preferences.notification.title') }}
                    </h3>
                    <div class="d-inline-flex float-right">
                      <div class="btn btn-sm btn-dark">
                        {{ $t('preferences.expand') }}
                      </div>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse id="notification" accordion="notification" role="tabpanel">
                  <b-card-body>
                    <b-card-text>

                      <!-- Notification channel: E-Mail -->
                      <div class="row mt-2">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('preferences.notification.email.title') }}
                            </h4>
                            <span>
                              {{ $t('preferences.notification.email.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <toggle-button ref="notificationCE" v-model="notification.email" @change="onTogglePreference('notification.email', $event)"
                                           :disabled="transmission['notification.email']"
                                           :sync="true"
                                           :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                           :height="40"
                                           :width="80"
                                           :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                           style="font-size: 13px !important;"
                                           class="m-0 mt-1 mb-1"
                            />
                            <div class="d-inline ml-3" v-if="$refs.notificationCE && $refs.notificationCE.disabled">
                              <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr>

                      <!-- Notifications: General -->
                      <div class="row mt-2">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('preferences.notifications.general.title') }}
                            </h4>
                            <span>
                              {{ $t('preferences.notifications.general.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <toggle-button ref="notificationsGeneral" v-model="notifications.general" @change="onTogglePreference('notifications.general', $event)"
                                           :disabled="transmission['notifications.general']"
                                           :sync="true"
                                           :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                           :height="40"
                                           :width="80"
                                           :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                           style="font-size: 13px !important;"
                                           class="m-0 mt-1 mb-1"
                            />
                            <div class="d-inline ml-3" v-if="$refs.notificationsGeneral && $refs.notificationsGeneral.disabled">
                              <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Notifications: Advertisement -->
                      <div class="row mt-2">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('preferences.notifications.advertisement.title') }}
                            </h4>
                            <span>
                              {{ $t('preferences.notifications.advertisement.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <toggle-button ref="notificationsAdvertisement" v-model="notifications.advertisement" @change="onTogglePreference('notifications.advertisement', $event)"
                                           :disabled="transmission['notifications.advertisement']"
                                           :sync="true"
                                           :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                           :height="40"
                                           :width="80"
                                           :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                           style="font-size: 13px !important;"
                                           class="m-0 mt-1 mb-1"
                            />
                            <div class="d-inline ml-3" v-if="$refs.notificationsAdvertisement && $refs.notificationsAdvertisement.disabled">
                              <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Notifications: Alert -->
                      <div class="row mt-2">
                        <div class="w-75">
                          <div class="ml-4 mr-4">
                            <h4>
                              {{ $t('preferences.notifications.alert.title') }}
                            </h4>
                            <span>
                              {{ $t('preferences.notifications.alert.description') }}
                            </span>
                          </div>
                        </div>
                        <div class="w-25">
                          <div style="text-align: center;">
                            <toggle-button ref="notificationsAlert" v-model="notifications.alert" @change="onTogglePreference('notifications.alert', $event)"
                                           :disabled="transmission['notifications.alert']"
                                           :sync="true"
                                           :color="{checked: '#34c38f', unchecked: '#f46a6a'}"
                                           :height="40"
                                           :width="80"
                                           :labels="{checked: $t('terms.on'), unchecked: $t('terms.off')}"
                                           style="font-size: 13px !important;"
                                           class="m-0 mt-1 mb-1"
                            />
                            <div class="d-inline ml-3" v-if="$refs.notificationsAlert && $refs.notificationsAlert.disabled">
                              <i class="far fa-circle-notch fa-spin text-white font-size-16"></i>
                            </div>
                          </div>
                        </div>
                      </div>


                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
